$(function() {
  $('.btn-gNav').on("click", function(){
    $(this).toggleClass('open');
    $('#gNav').toggleClass('open');
    $('.gNav-menu').toggleClass('hidden')
  });

});

// メニューをクリックされたら、非表示にする
$(function() {
  $('.gNav-menu li a').on("click", function(){
    $('#gNav').removeClass('open');
  });
  $('.gNav-menu li.btn-nav').on("click", function(){
    $('#gNav').removeClass('open');
    $('.btn-gNav').removeClass('open');
    $('.gNav-menu').toggleClass('hidden')
  });  
});